
        @import "@/assets/styles/app.scss";
      

table {
  border-collapse: collapse;
  background-color: #ffffff;
  margin: 0 auto;
  th {
    padding: 0.75rem;
  }
}
table tr:nth-child(even) {
  background-color: #ddd;
}
.order-row {
  // cursor: pointer;
  td {
    text-align: right;
    padding: 0.75rem;
    a {
      color: black;
    }
  }
}
.order-row:hover {
  background-color: #1976d2;
  color: white;
}
