
        @import "@/assets/styles/app.scss";
      

.form-wrapper{
    margin-top: 2rem;
}
.wrapper{
    padding-top: 5rem;
}
.page-wrapper{
    background-color: $accent;
    height: 100vh;
}
