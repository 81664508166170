
        @import "@/assets/styles/app.scss";
      

.card{
  background-color: $white;
  padding-bottom: 1rem;
  // margin-top: 6rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.card__title--primary{
  padding-top: 1rem;
}
