
        @import "@/assets/styles/app.scss";
      

.address-info {
  text-align: center;
  ul {
    list-style: none;
    li {
      font-size: 1.2rem;
    }
  }
}
.float-center {
  margin: 0 auto !important;
}
