
        @import "@/assets/styles/app.scss";
      


.login-page-container{
  background-color: $primary;
  background: url('../../assets/images/clouds-bg.gif') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.logo{
  margin: 4rem auto 2rem auto;
}

