
        @import "@/assets/styles/app.scss";
      

.credit {
    margin-top: 4rem;
}
    .list ul {
        list-style: none;
        padding-left: 0px;
        margin-left: 0px;
    }
    .list ul li {
        font-size: 1.2rem;
    }
    .costs .cell {
        font-size: 1.3rem;
    }
    .total {
        color: red;
        font-weight: bold;
    }
