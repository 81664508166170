
        @import "@/assets/styles/app.scss";
      

h1 {
  display: block;
  margin-bottom: 0px;
}
.headline {
  margin-bottom: 0px;
}
.header {
  padding-bottom: 0px;
}
.upload-drop-area {
  border: 4px dotted #ccc;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  .dz-message {
    font-size: 1rem;
  }
}
.upload-drop-area.is-dragging {
  border: 4px dotted green;
}
.attachments {
  ul {
    padding-left: 0px;
    list-style: none;
    margin-top: 0.5rem;
  }
}
.totals {
  margin-bottom: 2rem;
  strong {
    font-size: 2rem;
  }
}
.running-total {
  color: $success;
}

.standing-total {
  color: $info;
}
