
        @import "@/assets/styles/app.scss";
      

.super-total {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: $primary;
}
.main {
  padding-bottom: 2rem;
}
.header-row {
  margin-bottom: 2rem;
  .cell {
    div {
      background-color: #eee;
      padding: 0.375rem;
      font-weight: bold;
      font-size: 1.2rem;
      border-radius: 5px;
      text-align: center;
    }
  }
}
.total {
  text-align: right;
}
.items,
.packages {
  margin-bottom: 2rem;
}
.items .item-row .cell,
.packages .package-row .cell,
.grand-totals .total-row .cell {
  text-align: center;
}
.grand-totals .total-row .cell {
  font-weight: bold;
  font-size: 1.1rem;
}
.grand-totals .total-row .grand-total {
  font-size: 1.5rem;
  font-weight: bold;
  color: $primary;
}
.invoice-header {
  width: 100%;
  margin: 0 auto;
}
.invoice-header .cell ul {
  list-style: none;
  padding-left: 0px;
}
.c13-label {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
.invoice-area,
.grand-totals {
  position: relative;
}
.paid-stamp {
  border: 4px dotted $success;
  padding: 1rem;
  width: 500px;
  height: 200px;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  text-align: center;
  h3 {
    color: $success;
    font-family: Arial !important;
  }
  span {
    color: $success;
    font-size: 2rem;
  }
}
