// Load custom Foundation variables
@import "./settings";
// Load Foundation
@import "../../../node_modules/foundation-sites/scss/foundation";
//Init Foundation
@import "./global";

$primary: #d25a53;
$secondary: #91b958;
$success: #67ac5c;
$warn: #fcecb9;
$accent: #212121;
$error: #c3291c;
$info: #4995ec;

a,
button {
	cursor: pointer;
}

.input-group__input {
	border-bottom: 1px solid #ccc;
}

#layout .speed-dial {
	bottom: 70px;
}

.radio-group .input-group__input {
	border-bottom: none;
}

.fa {
	font-size: 1.4rem;
}

@media print {
	body .v-toolbar,
	body .v-navigation-drawer {
		visibility: hidden !important;
		// display: none !important;
	}

	.invoice-area,
	.invoice-area *,
	.shipment-report,
	.shipment-report *,
	.label *,
	.eod * {
		visibility: visible !important;
	}
	.invoice-area {
		display: block !important;
		width: 100vw !important;
		margin: 0 auto !important;
	}
	.shipment-report {
		position: absolute !important;
		left: -2rem !important;
		top: 0 !important;
		width: 100%;
	}
	.invoice-action-buttons,
	.c13-label {
		display: none !important;
	}
	.pagebreak {
		page-break-before: always;
	}
}

.all-pages-loaded {
	padding: 1rem;
	text-align: center;
	color: #7ab972;
	font-weight: bold;
}
.infinite-loading-container {
	width: 100%;
}

.dialog-close-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	font-weight: bold;
	cursor: pointer;
}
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
	background-color: grey !important;
}
