
        @import "@/assets/styles/app.scss";
      

table thead tr th,
table tbody tr td {
  padding: 5px;
}

table tbody tr td,
table thead tr th {
  border: 1px solid #bbb;
}
.packages a:visited {
  color: green;
}
