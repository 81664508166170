
        @import "@/assets/styles/app.scss";
      

table {
    border-collapse: collapse;
    background-color: #ffffff;
    margin: 0 auto;
    th {
        padding: .75rem;
    }
}
table tr:nth-child(even) {
    background-color: #ddd;
}
.office-row {
    // cursor: pointer;
    td {
        text-align: right;
        padding: .75rem;
        a {
            color: black;
        }
    }
}
.office-row:hover {
    background-color: #1976d2;
    color: white;
}
