
        @import "@/assets/styles/app.scss";
      

.cart-items {
  margin-bottom: 1rem;
}
.fa {
  cursor: pointer;
}
p {
  margin-bottom: 0px;
}
.cart-total {
  margin-bottom: 1.5rem;
}
.total-label,
.total-value {
  font-size: 1.5rem;
  color: $primary;
}
.delivery-label,
.delivery-value {
  font-size: 1.5rem;
  color: $success;
}
.memo {
  margin-bottom: 1rem;
}
