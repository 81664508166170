
        @import "@/assets/styles/app.scss";
      

table {
  border-collapse: collapse;
  background-color: #ffffff;
  margin: 0 auto;
  th {
    padding: 0.75rem;
  }
}
table tr:nth-child(even) {
  background-color: #ddd;
}
.order-row {
  td {
    text-align: center;
    padding: 0.75rem;
  }
}
.order-row:hover {
  background-color: #1976d2;
  color: white;
  td {
    a {
      color: white;
      text-decoration: underline;
    }
  }
}
.load-more-container {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}
.load-more-container select {
  background: #ddd;
  padding: 5px 15px;
}
