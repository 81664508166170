
        @import "@/assets/styles/app.scss";
      

.order-link {
  color: blue;
}
.pagination {
  padding: 10px;
  border: 1px solid #000000;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.filters {
  margin-top: 16px;
  margin-bottom: 16px;
}
table {
  border-collapse: collapse;
  background-color: #ffffff;
  margin: 0 auto;
  th {
    padding: 0.75rem;
  }
}
table tr:nth-child(even) {
  background-color: #ddd;
}
tbody tr {
  // cursor: pointer;
  td {
    text-align: right;
    padding: 0.75rem;
    a {
      color: black;
    }
  }
}
.filter input {
  padding: 4px;
  border: 1px solid #000000;
  margin-bottom: 6px;
}
