
        @import "@/assets/styles/app.scss";
      

.label {
  width: 384px;
  height: 576px;
  margin: 0 auto;
  padding-top: 0.5rem;
  .cell {
    margin-bottom: 0.5rem;
    text-align: center;
    .headline {
      font-size: 1.25rem !important;
      display: inline-block;
    }
  }
  ul {
    list-style: none;
    padding-left: 0px;
    text-align: center;
    li {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
.red-border {
  border: 5px solid $primary;
}
.green-border {
  border: 5px solid $secondary;
}
.order-number {
  font-size: 2rem !important;
}
@media print {
  @page {
    size: 4in 6in;
  }

  body * {
    visibility: hidden;
  }
  .label {
    width: 384px;
    height: 576px;
    margin: 0 auto;
    padding-top: 0.5rem;
    .cell {
      margin-bottom: 0.5rem;
      text-align: center;
      .headline {
        font-size: 1.25rem !important;
        display: inline-block;
      }
    }
    ul {
      list-style: none;
      padding-left: 0px;
      text-align: center;
      li {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
  .red-border {
    border: 5px solid $primary;
  }
  .green-border {
    border: 5px solid $secondary;
  }
  .order-number {
    font-size: 2rem !important;
  }
}
