
        @import "@/assets/styles/app.scss";
      

.fa{
    cursor: pointer;
}
.fa-minus-circle{
    font-size: .9rem !important;
}
.total-row{
    margin-top: 12px;
    .label, .amount{
        font-size: 1.2rem;
        font-size: $success;
    }
}
.cart-invoice{
    margin-top: .25rem;
    margin-bottom: .25rem
}
