
        @import "@/assets/styles/app.scss";
      

.headline {
  display: inline-block;
}
.item-title-wrapper,
.package-title-wrapper {
  width: 100%;
}
