
        @import "@/assets/styles/app.scss";
      

.calc-page-wrapper {
  height: 100vh;
  background-color: #fff;
}
.logo {
  margin-top: 100px;
}
