
        @import "@/assets/styles/app.scss";
      

.eod-table{
    margin-top: 2rem;
}

.total-block {
    color: white;
}
