
        @import "@/assets/styles/app.scss";
      

.location-state {
  p {
    margin-bottom: 0px;
    margin-top: 2rem;
    font-size: 2.5rem;
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: center;
  }
  p.harbor-bay {
    color: green;
  }
  p.madeira {
    color: red;
  }
}
.header-wrapper {
  width: 100%;
}
.packages {
  margin-top: 2rem;
  .package {
    margin-bottom: 1rem;
    .wrapper {
      width: 100%;
    }
    h3 {
      margin-bottom: 0px;
      display: inline-block;
    }
    .card__title {
      padding-bottom: 1rem;
    }
    .card__text {
      padding-top: 0px;
    }
  }
  .item {
    margin-bottom: 1rem;
  }
}
.upload-drop-area {
  border: 4px dotted #ccc;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  .dz-message {
    font-size: 1rem;
  }
}
.upload-drop-area.is-dragging {
  border: 4px dotted green;
}
.tracking-list {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  li {
    margin-bottom: 0.5rem;
    i {
      color: green;
    }
  }
}
.upload-remove-button {
  margin-left: 1rem;
  font-size: 1rem;
  cursor: pointer;
}
