
        @import "@/assets/styles/app.scss";
      

.main-wrapper {
  margin-top: 2rem;
}
.load-more-container {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}
.load-more-container select {
  background: #ddd;
  padding: 5px 15px;
}
.approve-btn {
  padding: 5px 10px;
  background: #ddd;
}
.approve-btn:hover {
  background: #1976d2;
  color: white;
}
.search-input {
  margin-bottom: 10px;
  input {
    padding: 5px 15px;
    background: #ddd;
    width: 300px;
    outline: none;
  }
}
