
        @import "@/assets/styles/app.scss";
      

.main-wrapper {
  margin-top: 2rem;
}
.load-more-container {
  margin-top: 1rem;
}
