
        @import "@/assets/styles/app.scss";
      

.container .logo img {
  width: 200px;
  height: auto;
}
.logo {
  width: 50%;
  height: auto;
}
.user-name {
  margin-right: 10px;
}
.greeting {
  margin-right: 2rem !important;
  position: relative;
}
.greeting .fa {
  font-size: 2rem;
  margin-left: 10px;
  cursor: pointer;
}
.cart-section {
  position: relative;
}
.cart {
  position: absolute;
  width: 300px;
  right: 0px;
}
.container.content {
  margin-top: 64px;
}
